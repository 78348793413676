import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchIncomeExpenseTypes(ctx, params) {
      return useJwt.getIncomeExpenseTypes(params).then(response => response);
    },
    createIncomeExpenseType(ctx, data) {
      return useJwt.createIncomeExpenseType(data).then(response => response);
    },
    updateIncomeExpenseType(ctx, data) {
      return useJwt.updateIncomeExpenseType(data).then(response => response);
    },
    deleteIncomeExpenseTypes(ctx, data) {
      return useJwt.deleteIncomeExpenseTypes(data).then(response => response);
    },
  },
};
