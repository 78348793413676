<template>

  <vue-good-table
    :columns="columns"
    :rows="items"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >

      <!-- Column: Status -->
      <span v-if="props.column.field === 'amount'">
        <validation-provider
          v-if="editable"
          #default="validationContext"
          :name="`amount ${props.row.originalIndex}`"
          rules="required"
        >
          <number-input
            v-model="props.row['amount']"
            :disabled="props.row.invoiceId > 0"
            :placeholder="t('Số tiền')"
            :default-value="props.row['amount']"
            :error="validationContext.errors[0]"
            @input="changeCell(props.row['amount'], 'amount', props.row.originalIndex)"
          />
        </validation-provider>
        <span v-if="!editable">{{ Number(props.row.amount).toLocaleString() }}</span>
      </span>

      <span v-else-if="props.column.field === 'startDate'">
        <validation-provider
          v-if="editable && props.row.incomeExpenseType.identity !== 'deduct'"
          #default="validationContext"
          :name="`Từ ngày ${props.row.originalIndex}`"
          rules="required"
        >
          <select-date
            v-model="props.row.startDate"
            :default-value="props.row.startDate"
            :error="validationContext.errors[0]"
            @input="changeCell(props.row['startDate'], 'startDate', props.row.originalIndex)"
          />

        </validation-provider>

      </span>

      <span v-else-if="props.column.field === 'endDate'">

        <validation-provider
          v-if="editable && props.row.incomeExpenseType.identity !== 'deduct'"
          #default="validationContext"
          :name="`Đến ngày ${props.row.originalIndex}`"
          rules="required"
        >
          <select-date
            v-model="props.row.endDate"
            :default-value="props.row.endDate"
            :error="validationContext.errors[0]"
            @input="changeCell(props.row['endDate'], 'endDate', props.row.originalIndex)"
          />

        </validation-provider>
      </span>

      <!-- Column: Action -->
      <span v-else-if="props.column.field === 'action'">
        <table-actions
          v-if="editable"
          :name="t('Hạng mục')"
          :hide-edit="true"
          :hide-delete="!editable || props.row.invoice"
          @on-delete="onDeleteItem(props.row)"
        />
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        {{ t('Không có bản ghi nào!') }}
      </div>
    </div>
  </vue-good-table>

</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import NumberInput from '@/views/components/NumberInput.vue';
import TableActions from '@/views/components/TableActions.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BSpinner,
    ValidationProvider,
    SelectDate,
    NumberInput,
    TableActions,
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: this.rows,
      required,
    };
  },
  watch: {
    rows(val) {
      this.items = val;
    },
  },
  methods: {
    onDeleteItem(item) {
      const remainData = this.items.filter(fee => fee.incomeExpenseType.id !== item.incomeExpenseType.id);
      this.items = remainData;
      this.onChange();
    },
    changeCell(changedData, column, row) {
      const oldData = this.items[row][column];
      if (oldData !== changedData) {
        this.items[row][column] = changedData;
        this.onChange();
      }
    },
    onChange() {
      this.$emit('input', this.items);
    },
  },
  setup(props) {
    const hidden = !props.editable || false;
    const { t } = useI18nUtils();
    // Table Handlers
    const columns = [
      {
        label: t('Hạng mục'),
        field: 'incomeExpenseType.name',
        sortable: false,
      },
      {
        label: t('Hóa đơn'),
        field: 'invoice.name',
        sortable: false,
        hidden: props.rows.filter(object => object.invoice && object.invoice.id > 0).length === 0,
      },
      {
        label: t('Số tiền'),
        field: 'amount',
        sortable: false,
        width: "150px",
      },
      {
        label: t('Ngày bắt đầu'),
        field: 'startDate',
        sortable: false,
        width: "140px",
        hidden,
      },
      {
        label: t('Ngày kết thúc'),
        field: 'endDate',
        sortable: false,
        width: "140px",
        hidden,
      },
      {
        label: t('Xóa'),
        field: 'action',
        sortable: false,
        width: "60px",
        hidden,
      },
    ];

    return {
      columns,
      t,
    };
  },
};
</script>
