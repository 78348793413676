<template>
  <b-modal
    id="modal-link-invoice"
    ref="refModalLinkInvoice"
    :title="t('Liên kết hóa đơn')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <b-alert
      v-if="item"
      show
      variant="danger"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="StarIcon"
        />
        <span class="ml-25">{{ t('Vui lòng chọn hóa đơn để liên kết cho khoản thu có nội dung chuyển khoản là') }} {{ itemLocal.tingeeTransaction ? `"${itemLocal.tingeeTransaction.content}"` : '' }} {{ t(' với số tiền là') }} {{ Number(itemLocal.amount).toLocaleString() }} đ.</span>
      </div>
    </b-alert>
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <b-col
            sm="6"
            :md="resolveColWidthIfDisableBed()"
          >
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                required
                :default-value="itemLocal.apartment"
                :label="t('Tòa nhà')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            sm="6"
            :md="resolveColWidthIfDisableBed()"
          >
            <validation-provider
              #default="validationContext"
              name="room"
              rules="required"
            >
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                required
                :apartment="itemLocal.apartment"
                :default-value="itemLocal.room"
                :label="t('Phòng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :show-status="true"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
            sm="6"
            :md="resolveColWidthIfDisableBed()"
          >
            <validation-provider
              #default="validationContext"
              name="bed"
              :rules="itemLocal.room && itemLocal.room.numberActiveBeds > 0 ? 'required' : ''"
            >
              <select-bed
                v-model="itemLocal.bed"
                :filter-active="true"
                :enable="itemLocal.room && itemLocal.room.numberActiveBeds > 0"
                :room="itemLocal.room"
                :default-value="itemLocal.bed"
                :label="t('Giường')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="invoice"
              rules="required"
            >
              <select-invoice
                v-model="itemLocal.invoice"
                :enable="itemLocal.room && itemLocal.room.id > 0"
                :apartment="itemLocal.apartment"
                :room="itemLocal.room"
                :bed="itemLocal.bed"
                :default-value="itemLocal.invoice"
                :label="t('Hóa đơn')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectInvoice from '@/views/components/SelectInvoice.vue';
import useLinkInvoiceModal from "./useLinkInvoiceModal";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BAlert,

    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectInvoice,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalLinkInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
      resolveColWidthIfDisableBed,
    } = useLinkInvoiceModal(props, emit, refFormObserver);

    return {
      refModalLinkInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      resolveColWidthIfDisableBed,
    };
  },
};
</script>
