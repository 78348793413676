import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { isEnableBed, isEnableLocationModule } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import incomeExpenseStoreModule from '../incomeExpenseStoreModule';

export default function useIncomeExpenseList() {
  const STORE_MODULE_NAME = 'incomeExpense';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, incomeExpenseStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    id: 0,
    isIncome: true,
    type: null,
    name: '',
    issueDate: moment(new Date()).toDate(),
    amount: null,
    cashbook: null,
    apartment: null,
    room: null,
    bed: null,
    tenant: null,
    contract: null,
    reservation: null,
    payer: '',
    receiver: '',
    note: '',
    allocation: true,
    allocationStartDate: null,
    allocationEndDate: null,
    items: [],
    // attachment
    attachments: [],
    // repeat
    repeatCycle: 0,
    repeatCycleObject: { title: 'Không lặp lại', value: 0 },
    repeatInfinity: true,
    repeatCount: 0,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'income-expense') && !vm.$can('delete', 'income-expense');
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      hidden: isHidden,
      width: '150px',
    },
    {
      label: t('Tên'),
      field: 'name',
    },
    {
      label: t('Số tiền thu'),
      field: 'amountIncome',
      type: 'number',
    },
    {
      label: t('Số tiền chi'),
      field: 'amountExpense',
      type: 'number',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartment',
    },
    {
      label: t('Thời gian'),
      field: 'issueDate',
    },
    {
      label: t('Người nhận/trả'),
      field: 'receiver',
    },
    {
      label: t('Sổ quỹ'),
      field: 'cashbook.name',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  // filter
  const cashbook = ref(null);
  const apartment = ref(null);
  const location = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref(null);
  const manager = ref(null);
  const type = ref(null);
  const isIncome = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  const approveStatus = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchIncomeExpenses = () => {
    isLoading.value = false;
    store
      .dispatch('incomeExpense/fetchIncomeExpenses', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('incomeExpense/fetchAnalytics', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('incomeExpense/exportIncomeExpenses', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAnalytics();
    fetchIncomeExpenses();
  };

  const downloadImportTemplate = () => {
    store
      .dispatch('incomeExpense/generateImportIncomeExpenses', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('incomeExpense/importIncomeExpenses', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteIncomeExpenses = incomeExpenses => {
    store
      .dispatch('incomeExpense/deleteIncomeExpenses', {
        ids: incomeExpenses.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const printIncomeExpense = incomeExpense => {
    store
      .dispatch('incomeExpense/getIncomeExpenseHtml', incomeExpense.id)
      .then(response => {
        const w = window.open();
        w.document.write(response.data);
        w.document.close();
        w.setTimeout(() => {
          w.print();
        }, 1000);
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const approveIncomeExpense = (incomeExpenses, approve) => {
    if (incomeExpenses && incomeExpenses.length > 0) {
      store.dispatch('incomeExpense/approveIncomeExpenses', { approve, ids: incomeExpenses.map(_obj => _obj.id) }).then(() => {
        fetchData();
      }).catch(error => {
        toastification.showToastError(error);
      });
    }
  };

  const setDeleteEnable = incomeExpenses => {
    // Chi khi tat ca hoa don chua duyet va chua thanh toan moi xoa duoc, con lai la khong hien thi nut xoa
    if (incomeExpenses.length === 0) {
      return false;
    }

    if (incomeExpenses.filter(obj => obj.approve === true).length > 0) {
      return false;
    }
    return true;
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Watch
  watch(cashbook, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.cashbookId = val.id;
    } else {
      delete filter.cashbookId;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });
  watch(manager, val => {
    const { filter } = serverParams.value;
    if (val && val.managerId) {
      filter.creatorId = val.managerId;
    } else {
      delete filter.creatorId;
    }
    updateParams({ filter });
  });
  watch(startDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.startDate = val;
    } else {
      delete filter.startDate;
    }
    updateParams({ filter });
  });
  watch(endDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.endDate = val;
    } else {
      delete filter.endDate;
    }
    updateParams({ filter });
  });
  watch(isIncome, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.isIncome = val.value;
      if (val.isRepeat) {
        filter.isRepeat = true;
      }
    } else {
      delete filter.isIncome;
      delete filter.isRepeat;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(approveStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.approve = val.value;
    } else {
      delete filter.approve;
    }
    updateParams({ filter });
  });

  const setApproveHidden = incomeExpenses => {
    // Tat ca hoa don dang o trang thai chua duyet thi se hien thi nut approve

    const numOfApprove = incomeExpenses.filter(obj => obj.approve === false).length;
    return !(incomeExpenses.length > 0 && numOfApprove === incomeExpenses.length);
  };

  const setDeclineHidden = incomeExpenses => {
    // Hiển thị khi tất cả hóa đơn ở trạng thái đã duyệt
    const numOfDecline = incomeExpenses.filter(obj => obj.approve === true).length;
    return !(incomeExpenses.length > 0 && numOfDecline === incomeExpenses.length);
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 2;
    }
    if (isBed) {
      return 0;
    }
    return 3;
  };

  return {
    analytic,
    item,
    columns,
    rows,
    cashbook,
    location,
    apartment,
    room,
    bed,
    status,
    type,
    isIncome,
    startDate,
    endDate,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    manager,
    setDeleteEnable,
    fetchData,
    fetchIncomeExpenses,
    deleteIncomeExpenses,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    printIncomeExpense,
    exportData,
    importData,
    downloadImportTemplate,
    setApproveHidden,
    setDeclineHidden,
    approveIncomeExpense,
    approveStatus,
    resolveColWidthIfDisableBed,
    t,
    isEnableLocationModule,
    isTingTong,
  };
}
