<template>
  <b-modal
    id="modal-income-expense-type"
    ref="refModal"
    :title="t('Loại thu chi')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Title -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Tên loại thu chi"
            class="mt-2"
          >
            <template
              v-slot:label
            >
              {{ t('Tên loại thu chi') }}
              <span
                class="text-danger"
              >
                (*)
              </span>
            </template>
            <b-form-input
              id="name"
              v-model="itemLocal.name"
              placeholder=""
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-form-group
          :label="t('Mã tham chiếu')"
          class="mt-2"
        >

          <b-form-input
            id="refId"
            v-model="itemLocal.refId"
            placeholder=""
            trim
          />

        </b-form-group>
        <validation-provider
          #default="validationContext"
          name="identityObject"
          rules="required"
        >
          <select-fee-identity
            v-model="itemLocal.identityObject"
            :default-value="itemLocal.identityObject"
            required
            :label="t('Phân loại')"
            :state="getValidationState(validationContext)"
            :error="validationContext.errors[0]"
          />
        </validation-provider>

        <!-- Note -->
        <b-form-group
          :label="t('Mô tả')"
        >
          <b-form-textarea
            id="description"
            v-model="itemLocal.description"
            :placeholder="t('Mô tả')"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectFeeIdentity from '@/views/components/SelectFeeIdentity.vue';
import useIncomeExpenseTypeHandler from './useIncomeExpenseTypeHandler';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    SelectFeeIdentity,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
    } = useIncomeExpenseTypeHandler(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
