import {
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import {
  isEnableBed,
} from '@/auth/utils';
import incomeExpnseStoreModule from '../incomeExpenseStoreModule';

export default function useLinkInvoiceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'incomeExpense';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, incomeExpnseStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const refModalLinkInvoice = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);

  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      incomeExpenseId: itemLocal.value.id,
      invoiceId: itemLocal.value.invoice ? itemLocal.value.invoice.id : null,
    };

    isSubmitting.value = true;

    store
      .dispatch('incomeExpense/linkInvoice', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalLinkInvoice.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  return {
    refModalLinkInvoice,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
    resolveColWidthIfDisableBed,
  };
}
