<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="type"
      v-model="type"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="types"
      :placeholder="t('Phân loại')"
      :disabled="!enable"
      @input="onChanged"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: this.defaultValue,
      types: [
        { title: 'Tiền nhà', value: 'lease' },
        { title: 'Tiền cọc', value: 'deposit' },
        { title: 'Tiền điện', value: 'electric' },
        { title: 'Tiền nước', value: 'water' },
        { title: 'Tiền môi giới', value: 'sale' },
        { title: 'Tiền vệ sinh', value: 'cleaning' },
        { title: 'Tiền mạng', value: 'internet' },
        { title: 'Tiền phí quản lý', value: 'management' },
        { title: 'Tiền gửi xe', value: 'vehicle' },
        { title: 'Tiền phí dịch vụ', value: 'service' },
        { title: 'Tiền giặt là', value: 'washing' },
        { title: 'Tiền vốn đầu tư', value: 'capital' },
        { title: 'Tiền hóa đơn', value: 'invoice' },
        { title: 'Tiền thừa/trả trước', value: 'deduct' },
        { title: 'Tiền gas', value: 'gas' },
        { title: 'Tiền phạt', value: 'punish' },
        { title: 'Tiền sửa chữa', value: 'fixing' },
        { title: 'Tiền lương', value: 'salary' },
        { title: 'Tiền phí giao dịch', value: 'transaction' },
        { title: 'Tiền giảm giá', value: 'discount' },
        { title: 'Tiền phí nhượng', value: 'transfer' },
        { title: 'Tiền phí chuyển', value: 'move' },
        { title: 'Tiền thuế', value: 'tax' },
        { title: 'Luân chuyển tiền quỹ', value: 'internal_transfer' },
        { title: 'Hoạt động kinh doanh', value: 'business_fee' },
        { title: 'Mua sắm tài sản', value: 'inventory' },
        { title: 'Tiền khác', value: 'other' },
      ],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    onChanged() {
      this.$emit('input', this.type);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
