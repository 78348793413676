<template>
  <b-modal
    id="modal-income-expense-detail"
    ref="refModalIncomeExpenseDetail"
    :title="t('Thông tin thu chi')"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-alert
        :show="itemLocal.repeatIncomeExpense != null && temLocal.repeatIncomeExpenseId > 0"
        variant="danger"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="AlertTriangleIcon"
          />
          <span class="ml-25">{{ t('Phiếu này được lập tự động theo chu kỳ đã cấu hình tại phiếu') }} <b-link @click="$emit('on-click-repeat-income-expense')">{{ itemLocal.repeatIncomeExpense && itemLocal.repeatIncomeExpense.code ? itemLocal.repeatIncomeExpense.code : '' }}</b-link>.</span>
        </div>
      </b-alert>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Mã phiếu') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.code }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Số tiền') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.amount).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.cashbook">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.cashbook.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tòa nhà') }}:
            </b-col>
            <b-col class="">
              <strong>{{ itemLocal.apartment.name }}</strong>
              <span v-if="itemLocal.room"> {{ t('phòng') }} <strong>{{ itemLocal.room.name }}</strong></span>
              <span v-if="itemLocal.bed"> {{ t('giường') }} <strong>{{ itemLocal.bed.name }}</strong></span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.note">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ghi chú') }}:
            </b-col>
            <b-col class="">
              <span style="white-space: pre-line;">{{ itemLocal.note }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.receiver">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Người nhận') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.receiver }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.payer">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Người nộp') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.payer }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.issueDate">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Thời gian') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.issueDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.issueDate">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày tạo') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.created_at, "DD-MM-YYYY HH:mm") }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.creator && itemLocal.creator.user">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Người tạo') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.creator.user.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.repeatCycleObject">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tự động lặp') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.repeatCycleObject.title }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>
      <b-list-group
        v-if="itemLocal.items && itemLocal.items.length > 0"
        class="mt-2"
      >
        <b-list-group-item
          v-for="eachItem in itemLocal.items"
          :key="eachItem.id"
        >
          <b-row>
            <b-col class="font-weight-bolder">
              {{ eachItem.name }}:
            </b-col>
            <b-col class="">
              {{ Number(eachItem.amount).toLocaleString() }} đ
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

      <media-view
        v-model="itemLocal.attachments"
        :label="null"
        :initial-value="itemLocal.attachments"
        :upload-imediately="true"
        :target-object="{id: itemLocal.id, type: 'income-expense'}"
        :preview-mode="!$can('update', 'income-expense')"
      />
    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BAlert,
  BLink,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { parseDateToString } from '@/auth/utils';
import MediaView from '@/views/components/media/MediaView.vue';
import useIncomeExpenseDetailModal from './useIncomeExpenseDetailModal';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    MediaView,
    BAlert,
    BLink,
  },
  props: {
    incomeExpenseId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
      isTingTong: process.env.VUE_APP_PROVIDER === 'tingtong',
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      t,
      onDeleteAttachment,
    } = useIncomeExpenseDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      onDeleteAttachment,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      parseDateToString,
      isLoading,
      t,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
