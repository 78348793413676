var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.label,"state":_vm.state},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('v-select',{attrs:{"id":"invoice","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"get-option-label":function (option) { return option.name; },"options":_vm.invoices,"placeholder":_vm.t('Hóa đơn'),"disabled":!_vm.enable},on:{"input":_vm.valueChanged,"click":_vm.onClick,"search":_vm.onSearchResult},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var totalDebt = ref.totalDebt;
return [_vm._v(" "+_vm._s(name)+" "),_c('br'),_c('cite',[_vm._v(_vm._s(Number(totalDebt).toLocaleString()))])]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" "+_vm._s(_vm.t('Không có dữ liệu'))+" ")]}}]),model:{value:(_vm.invoice),callback:function ($$v) {_vm.invoice=$$v},expression:"invoice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }